<template>
  <div class="daterange-outer">
    <DatePicker
      v-model="startDate"
      ref="startRef"
      :size="size"
      :type="dateType"
      @change="changeStart"
      class="daterange"
      :defaultTime="['00:00:00']"
    >></DatePicker>
    <DatePicker
      v-model="endDate"
      ref="endRef"
      :size="size"
      :type="dateType"
      @change="changeEnd"
      :defaultTime="['23:59:50']"
    >></DatePicker>

    <el-dropdown v-if="typeOptions.length > 1" @command="dateType=$event">
      <div class="el-dropdown-link">
        {{typeOptions.find(p=>p.type===dateType).placeholder}}
        <i
          class="el-icon-arrow-down el-icon--right"
        ></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="item in typeOptions"
          :key="item.type"
          :command="item.type"
        >{{item.placeholder}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import DatePicker, { typeOptions } from "./DatePicker";

import moment from 'dayjs';

export default {
  name: "baseDateRange",
  components: {
    DatePicker,
  },
  model: {
    prop: "value",
    event: "value-change",
  },
  props: {
    value: {
      type: Array,
      default() {
        return ["", ""];
      },
    },
    size: {
      type: String,
      default: "mini",
    },
    type: {
      type: String,
      default: "date",
    },
    types: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      startStr: '',
      endStr: '',
      dateType: this.type || "date",
    };
  },
  computed: {
    typeOptions() {
      return typeOptions.filter(
        (p) => !["year"].includes(this.type) && this.types.includes(p.type)
      );
    },
    startDate: {
      get() {
        return this.value[0] || this.startStr;
      },
      set(val) {
        this.startStr = val;
        this.$emit("value-change", [this.startStr, this.endDate]);
      },
    },
    endDate: {
      get() {
        return this.value[1] || this.endStr;
      },
      set(val) {
        this.endStr = val;
        this.$emit("value-change", [this.startDate, this.endStr]);
      },
    },
  },
  watch: {
    async dateType() {
      await this.$nextTick();
      // if (this.$refs.startRef) this.$refs.startRef.clear();
      // if (this.$refs.endRef) this.$refs.endRef.clear();
      this.change([this.startDate, this.endDate]);
    },
  },
  beforeMount() {
    const endStr = moment(new Date()).format("YYYY-MM-DD 23:59:59");
    const start = new Date(endStr);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
    const startStr = moment(new Date(start)).format("YYYY-MM-DD 00:00:00");
    this.startStr = startStr;
    this.endStr = endStr;
    this.change([this.startStr, this.endStr]);
    
    // if (this.type) this.dateType = this.type;
  },
  mounted() {
  },
  methods: {
    changeStart(val) {
      this.change([val, this.endDate]);
    },
    changeEnd(val) {
      this.change([this.startDate, val]);
    },
    change(dates) {
      //#region  moment 测试
      // if (dates[0]) {
      //   const t = [moment(dates[0])]
      //   const m = ['startOf', 'endOf'][1];
      //   t[t.length] = moment(dates[0])[m]('year');    // 设置为今年一月1日上午 12:00
      //   t[t.length] = moment(dates[0])[m]('month');   // 设置为本月1日上午 12:00
      //   t[t.length] = moment(dates[0])[m]('quarter');  // 设置为当前季度的开始，即每月的第一天上午 12:00
      //   t[t.length] = moment(dates[0])[m]('week');    // 设置为本周的第一天上午 12:00
      //   t[t.length] = moment(dates[0])[m]('isoWeek'); // 根据 ISO 8601 设置为本周的第一天上午 12:00
      //   t[t.length] = moment(dates[0])[m]('day');     // 设置为今天上午 12:00
      //   t[t.length] = moment(dates[0])[m]('date');     // 设置为今天上午 12:00
      //   t[t.length] = moment(dates[0])[m]('hour');    // 设置为当前时间，但是 0 分钟、0 秒钟、0 毫秒
      //   t[t.length] = moment(dates[0])[m]('minute');  // 设置为当前时间，但是 0 秒钟、0 毫秒
      //   t[t.length] = moment(dates[0])[m]('second');  // 与 moment().milliseconds(0); 相同

      //   console.log(t.map(p => p.format('YYYY-MM-DD HH:mm:ss.SSS')))
      // console.log(moment(dates[0]).week())
      // console.log(moment(dates[0]).weekYear())
      // console.log(moment(dates[0]).weeksInYear())
      // console.log(moment(dates[0]).year())
      //   console.log(moment(dates[0]).month())
      // }
      //#endregion

      if (
        dates.every((d) => d) &&
        new Date(dates[0]).getTime() <= new Date(dates[1]).getTime()
      ) {
        if (!["datetime"].includes(this.dateType)) {
          let type = this.dateType;
          if (type === "date") type = "day";
          if (type === "week") {
            dates[0] = moment(dates[0])
              .startOf("week")
              .format("YYYY-MM-DD HH:mm:ss");
            dates[1] = moment(dates[1])
              .startOf("week")
              .format("YYYY-MM-DD HH:mm:ss");
          }
          if (['month', 'year'].includes(type)) {
            dates[0] = moment(dates[0])
              .startOf(type)
              .format("YYYY-MM-DD HH:mm:ss");
            dates[1] = moment(dates[1])
              .endOf(type)
              .format("YYYY-MM-DD HH:mm:ss");
          }

          const range = moment.range(dates[0], dates[1]);

          dates = Array.from(range.by(type)).map((m) =>
            m.format("YYYY-MM-DD HH:mm:ss")
          );
        }

        this.$emit("change", this.dateType, dates);
      } else {
        this.$emit("change", this.dateType, []);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.daterange {
  margin: 0 10px 0 0;
}

.el-dropdown-link {
  cursor: pointer;
  width: 80px;
  text-align: end;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>