<template>
  <el-date-picker
    class="datepick-outer"
    v-bind="$props"
    v-model="dateValue"
    :type="type"
    :format="dateFormat"
    :placeholder="datePlaceholder"
    :size="size"
    :picker-options="pickerOptions"
    value-format="yyyy-MM-dd HH:mm:ss"
    @change="onChange"
  ></el-date-picker>
</template>

<script>
export const typeOptions = [
  { type: 'year', format: 'yyyy', placeholder: '选择年' },
  { type: 'month', format: 'yyyy-MM', placeholder: '选择月' },
  { type: 'week', format: 'yyyy 第 WW 周', placeholder: '选择周' },
  { type: 'date', format: 'yyyy-MM-dd', placeholder: '选择日' },
  { type: 'datetime', format: 'yyyy-MM-dd HH:mm:ss', placeholder: '选择时间' },
];

export default {
  name: 'baseDatePicker',
  model: {
    prop: 'value',
    event: 'value-change'
  },
  props: {
    value: {
      type: [String, Date],
      default: '',
    },
    size: {
      type: String,
      default: 'small',
      validator(val) {
        return ['large', 'small', 'mini'].includes(val);
      }
    },
    type: {
      type: String,
      default: 'date',
      validator(val) {
        return typeOptions.some(p => p.type === val);
      }
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    defaultTime: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dateStr: '',
      pickerOptions: {
        firstDayOfWeek: 7,
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
    };
  },
  computed: {
    dateFormat() {
      return this.format || typeOptions.find(p => p.type === this.type).format;
    },
    datePlaceholder() {
      return this.placeholder || typeOptions.find(p => p.type === this.type).placeholder;
    },
    dateValue: {
      get() {
        return this.value || this.dateStr;
      },
      set(val) {
        this.dateStr = val;
        this.$emit('value-change', val);
      }
    }
  },
  methods: {
    clear() {
      this.dateStr = '';
      this.dateValue = '';
    },
    onChange(date) {
      this.$emit('change', date);
    }
  }
}
</script>

<style lang="scss" scoped>
.datepick-outer {
  width: 200px;
}
</style>