<template>
  <div class="TimeRangePicker">
    <el-time-picker
      is-range
      v-model="timeRange"
      :value-format="valueFormat"
      :size="size"
      :range-separator="rangeSeparator"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      :align="align"
      :clearable="false"
      @change="timeChange"
    ></el-time-picker>
  </div>
</template>

<script>
export default {
  name: 'TimeRangePicker',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    size: {
      type: String,
      default: 'mini',
    },
    align: {
      type: String,
      default: 'right',
    },
    rangeSeparator: {
      type: String,
      default: '至',
    },
    valueFormat: {
      type: String,
      default: 'HH:mm:ss',
    }
  },
  data() {
    return {
      range: [],
    };
  },
  computed: {
    timeRange: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    timeChange(value) {
      this.$emit('change', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.TimeRangePicker {
  width: 100%;
  position: relative;
}
::v-deep.el-range-editor {
  width: 100%;
  .el-icon-time,
  .el-range__close-icon {
    display: none;
  }
  .el-range-separator {
    width: 10%;
  }
  .el-range-input {
    width: 45%;
  }
}
</style>